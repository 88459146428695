import "../../../../styles/searchHome.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import {tokenLink, link} from "../../../services/variables"
import styled, { css } from "styled-components";

export default function SearchHome() {
  const [search, setSearch] = useState("");
  const [nameimg, setNameimg] = useState("");

  const navigate = useNavigate();

  const selectOpen = (e) => {
    e.preventDefault();
    navigate(`/pesquisar/${search}/`);
  };

  //background
  useEffect(() => {
    var img = [
      "/imagens/01.jpg",
      "/imagens/02.jpg",
      "/imagens/03.jpg",
      "/imagens/04.jpg",
    ];
    const numberMinute = parseInt(localStorage.getItem("numberMinute"));
    const idImg = parseInt(localStorage.getItem("IdImg"));

    if (idImg != null && idImg < img.length) {
      //se não existir imagem e se for menor que o array
      if (Number.isNaN(numberMinute)) {
        // não existir tempo no local Storage
        localStorage.setItem("numberMinute", Date.now());
        localStorage.setItem("IdImg", 0);
      } else if (1 < Math.floor((Date.now() - numberMinute) / 1000 / 60)) {
        // TEmpo para mudar a imagem
        const newImgId = idImg + 1;
        localStorage.setItem("numberMinute", Date.now());
        localStorage.setItem("IdImg", newImgId);
        document.getElementById(
          "back-search-main-portal"
        ).style.backgroundImage = `url(${img[newImgId]})`;
      } else {
        document.getElementById(
          "back-search-main-portal"
        ).style.backgroundImage = `url(${img[idImg]})`;
      }
    } else if (idImg == img.length) {
      //se o tamanho for igual
      document.getElementById(
        "back-search-main-portal"
      ).style.backgroundImage = `url(${img[0]})`;
      localStorage.setItem("IdImg", 0);
      localStorage.setItem("numberMinute", Date.now());
    } else {
      document.getElementById(
        "back-search-main-portal"
      ).style.backgroundImage = `url(${img[0]})`;
      localStorage.setItem("IdImg", 0);
      localStorage.setItem("numberMinute", Date.now());
    }

    // if(numberMinute != null){
    //   if(parseInt(numberMinute)+15 >= 60){
    //     localStorage.setItem('numberMinute',data.getMinutes())

    //   }

    //   if(data.getMinutes() > parseInt(numberMinute) +1){
    //     console.log("mudar")
    //     console.log(data.getMinutes(), "atual")
    //     console.log(parseInt(numberMinute)+15 ,"salvo")

    // if(parseInt(idImg) < img.length){
    //   const a = parseInt(idImg)+1
    //   setNameimg(img[a])
    //   if(nameimg == undefined){
    //     localStorage.setItem('IdImg', 0)
    //     setNameimg(img[0])
    //   }else{

    //     console.log(nameimg)
    //     localStorage.setItem('IdImg', a)
    //   }
    // }else{
    //   localStorage.setItem('IdImg', 0)
    //   setNameimg(img[0])

    // }

    // }else{
    //   const a = parseInt(idImg)
    //   setNameimg(img[a])
    // }
    // }else{
    //   setNameimg(img[0])
    // console.log(img[0])
    // localStorage.setItem('numberMinute',data.getMinutes())
    // localStorage.setItem('IdImg', 0)

    // }

    //document.getElementById('back-search-main-portal').style.backgroundImage=`url(${nameimg})`;
  }, []);
  //background
  // useEffect(()=>{
  //     //comentar essa parte se for estático
  //      document.getElementById('back-search-main-portal').style.backgroundImage=`url(${nameimg})`;
  // },[nameimg])

  const BackgroundIMG = styled.div`
    background-image: url(${nameimg});
    //background-color: #e52323f2;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: rgb(0, 0, 0);
    padding: 12%;
  `;

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="background-global" id="back-search-main-portal">
      <div className="container-search-txt">
        <div className="container-search-txt1">
          {/* <h1 className='txt-init'>Bem-Vindos</h1>
   <h5 className='txt-init'>Portal da transparência</h5> */}
        </div>
      </div>

      <form className="container-search">
        <input
          type="text"
          placeholder="Buscar"
          name="search-main"
          className="input-search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <button onClick={(e) => selectOpen(e)} className="link-search">
          <img
            src="/icons/search.svg"
            alt="logo buscar"
            className="svg-search"
          />
        </button>
      </form>
    </div>
  );
}
